<template>
  <div class="content">
    <div class="main">
      <div class="screen_view_wrap">
        <screen-view @change="change_screen" test />
      </div>
      <el-row v-if="list.length != 0">
        <el-col
          class="colView"
          :xs="12"
          :sm="8"
          :md="6"
          :lg="4"
          v-for="(item, index) of list"
          :key="index"
        >
          <product-item :item="item" img-key-name="attachment_url" />
        </el-col>
      </el-row>
      <empty-data icon-size="60px" font-size="16px" v-else />
    </div>
  </div>
</template>

<script>
import screenView from "@/components/screen";
import productItem from "@/components/product-item";
export default {
  name: "Product",
  components: {
    screenView,
    productItem,
  },
  data() {
    return {
      is_index: 0, //是否首页,1是0否
      sample_id: 0, //样品id
      standard_id: 0, //标准id
      test_id: 0, //测试id
      list: [],
      industry_id: 0
    };
  },
  mounted() {
    this.getData();
  },
  watch: {
    $route(to, from) {
      this.getData();
    },
  },
  methods: {
    /**
     * 筛选回调
     */
    change_screen(e) {
      this.sample_id = e.sample;
      this.standard_id = e.standard;
      this.test_id = e.test;
      this.getData();
    },
    getData() {
      this.$http
        .product({
          is_index: this.is_index,
          sample_id: this.sample_id,
          standard_id: this.standard_id,
          test_id: this.test_id,
          industry_id: this.$route.query.industry_id
        })
        .then((res) => {
          if (res.code == 200) {
            this.list = res.data.data;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/product.scss";
</style>