<template>
  <div class="screenView">
    <h4>{{ $t("localization.components.screen.title") }}</h4>
    <el-form :inline="true" :model="form" class="formView" ref="form">
      <el-form-item
        :label="$t('localization.components.screen.test.label')"
        prop="test"
        v-if="test"
      >
        <el-select
          v-model="form.test"
          :placeholder="$t('localization.components.screen.test.placeholder')"
          @change="changeData"
          clearable
        >
          <el-option
            v-for="item in testList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        v-if="test"
        :label="$t('localization.components.screen.standard.label')"
        prop="standard"
      >
        <el-select
          v-model="form.standard"
          :placeholder="
            $t('localization.components.screen.standard.placeholder')
          "
          @change="changeData"
          clearable
        >
          <el-option
            v-for="item in standardList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <!-- 产品 -->
      <el-form-item
        v-else
        :label="$t('localization.components.screen.product.label')"
        prop="standard"
      >
        <el-select
          v-model="form.standard"
          :placeholder="
            $t('localization.components.screen.product.placeholder')
          "
          @change="changeData"
          clearable
        >
          <el-option
            v-for="item in standardList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item
        :label="$t('localization.components.screen.sample.label')"
        prop="sample"
      >
        <el-select
          v-model="form.sample"
          :placeholder="$t('localization.components.screen.sample.placeholder')"
          @change="changeData"
          clearable
        >
          <el-option
            v-for="item in sampleList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <el-link
      class="refresh"
      @click="refresh"
      icon="el-icon-refresh-right"
      :underline="false"
      >{{ $t("localization.components.screen.refresh") }}</el-link
    >
  </div>
</template>

<script>
export default {
  props: {
    //高亮
    activeName: {
      type: String,
      default() {
        return "";
      },
    },
    //是否有测试
    test: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  //监听路由变化
  watch: {
    activeName: {
      handler: function (to, form) {
        this.sampleList = [];
        this.standardList = [];
        this.testList = [];
        this.form = {
          test: "",
          standard: "",
          sample: "",
        };
      },
      immediate: true,
    },
    test: {
      handler: function (new_vlaue) {
        this.getData(new_vlaue);
      },
      immediate: true,
    },
  },
  data() {
    return {
      form: {
        test: "",
        standard: "",
        sample: "",
      },
      sampleList: [],
      standardList: [],
      testList: [],
    };
  },
  mounted() {},
  methods: {
    /**
     * 获取筛选数据
     */
    getData(bo) {
      if (bo) {
        this.$http.productSelectList().then((res) => {
          if (res.code == 200) {
            this.sampleList = res.data.sample;
            this.standardList = res.data.standard;
            this.testList = res.data.test;
          } else {
            this.$message.error(res.msg);
          }
        });
      } else {
        this.$http.partSelectList().then((res) => {
          if (res.code == 200) {
            this.sampleList = res.data.sample;
            this.standardList = res.data.standard;
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    refresh() {
      this.$refs.form.resetFields();
      this.changeData();
    },
    changeData() {
      let data = {
        standard: this.form.standard == "" ? 0 : this.form.standard,
        sample: this.form.sample == "" ? 0 : this.form.sample,
      };
      if (this.test) {
        data.test = this.form.test == "" ? 0 : this.form.test;
      }
      this.$emit("change", data);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.screenView {
  display: flex;
  align-items: center;
  background-color: #f0f4f5;
  border-radius: 5px;
  padding: 26px 40px;
  @include respond-to(max1280) {
    padding: 20px;
  }
  @include respond-to(md) {
    flex-direction: column;
    position: relative;
  }
  h4 {
    font-size: 24px;
    color: #333;
    @include respond-to(max1280) {
      font-size: 20px;
    }
    @include respond-to(md) {
      margin-bottom: 20px;
    }
    @include respond-to(sm) {
      font-size: 18px;
    }
  }
  .formView {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: space-between;
    margin: 0 50px;
    @include respond-to(max1280) {
      margin: 0 20px;
    }
    @include respond-to(sm) {
      flex-direction: column;
      margin: 0;
      width: 100%;
    }
    /deep/ .el-form-item {
      margin-bottom: 0;
      @include respond-to(sm) {
        width: 100%;
        margin-right: 0;
        display: flex;
        margin-bottom: 10px;
      }
      .el-form-item__label {
        font-size: 18px;
        color: #333;
        @include respond-to(max1280) {
          font-size: 16px;
        }
      }
      .el-form-item__content {
        @include respond-to(lg) {
          width: 170px;
        }
        @include respond-to(sm) {
          width: auto;
          flex: 1;
        }
        .el-select {
          @include respond-to(sm) {
            width: 100%;
          }
        }
      }
    }
  }
  .refresh {
    font-size: 18px;
    color: #333;
    @include respond-to(max1280) {
      font-size: 16px;
    }
    @include respond-to(md) {
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }
}
</style>