<!--
 * @Author: 苏航
 * @Date: 2021-05-25 08:35:14
 * @LastEditors: 苏航
 * @LastEditTime: 2021-08-27 11:38:58
 * @version: V.x
 * @Descripttion: 说明...
-->
<template>
  <router-link :to="`/product_details?id=${item.id}`" class="link">
    <div class="imgBox">
      <img :src="item[imgKeyName]" alt="" srcset="" />
    </div>
    <div class="title">{{ item.e_title }}</div>
    <div class="name">{{ item.title }}</div>
  </router-link>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    imgKeyName: {
      type: String,
      default() {
        return "";
      },
    },
  },

  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.link {
  display: flex;
  flex-direction: column;
  align-items: center;

  .imgBox {
    max-width: 210px;

    img {
      display: block;
      width: 100%;
    }
  }

  .name {
    font-size: 18px;
    color: #333;
    margin-top: 1vw;
    height: 48px;
    text-align: center;
    @include respond-to(max1366) {
      font-size: 14px;
      margin-top: 6px;
    }
  }
  .title {
    font-size: 18px;
    color: #333;
    height: 22px;
    margin-top: 1vw;
    @include respond-to(max1366) {
      font-size: 14px;
      margin-top: 6px;
    }
  }

  &:hover {
    .title,
    .name {
      color: #0075c1;
    }
  }
}
</style>